import { Skeleton } from 'primereact/skeleton';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import EmptyScreen from '../../../../../../components/empty-screen/EmptyScreen';
import { useGetFootprintsByProductId } from '../../../../../../hooks/api/footprint';
import FootprintDetailsPage from '../../../../../footprint-details/FootprintDetailsPage';
import CreatePcfButton from './components/create-pcf-button/CreatePcfButton';
import PcfTable from './components/pcf-table/PcfTable';

const PcfTab = (): JSX.Element => {
  const { t } = useTranslation();

  // Read params from URL
  const { productId, footprintId } = useParams() as { productId: string; footprintId: string };

  const { data: footprints, isLoading } = useGetFootprintsByProductId(productId);

  if (footprintId) {
    return <FootprintDetailsPage />;
  }

  return (
    <div>
      <h1 className='text-xl md:text-2xl font-medium p-0 my-3'>
        {t('productDetailsPage.tabs.pcfTab.pcfHeader')}
      </h1>
      {isLoading ? (
        <div>
          <Skeleton
            height='15rem'
            className='flex w-full flex-row justify-content-center mb-3 border-round-xl'
          />
          <Skeleton height='2.5rem' width='10rem' className='border-round-lg' />
        </div>
      ) : (
        <div>
          {footprints?.length ? (
            <div>
              <PcfTable productId={productId} />
            </div>
          ) : (
            <div>
              <EmptyScreen>
                <p className='flex text-center font-light'>
                  {t('productDetailsPage.tabs.pcfTab.emptyScreenText')}
                </p>
                <CreatePcfButton />
              </EmptyScreen>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PcfTab;
