// created enums to manage all routes through single file
export enum AppRoutes {
  Login = '/login',
  ActivateAccount = '/activate-account',
  Products = '/products',
  Components = '/components',
  ComponentDetails = '/components/:componentId',
  Requests = '/requests',
  NotFound = '/404',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password',
  PrivacyPolicy = '/privacy-policy',
  TermsOfUse = '/terms-of-use',
  UserManagement = '/user-management',
  ProductDetailsTab = '/products/:productId/product-details',
  ProductPcfTab = '/products/:productId/pcf',
  ProductPermissionTab = '/products/:productId/permissions',
  ProductFootprintDetails = '/products/:productId/pcf/footprint/:footprintId',
}
