/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { useGetFootprintById } from '../../hooks/api/footprint';

const FootprintDetailsPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { productId, footprintId } = useParams() as { productId: string; footprintId: string };

  // Getting the footprint details
  const {
    // data: footprintDetails,
    // isLoading,
    isError,
  } = useGetFootprintById(footprintId, {
    enabled: !!footprintId,
    retry: 0,
  });

  // If footprint details not found then navigate to 404 page
  if (isError) {
    return <Navigate to='/404' />;
  }

  return (
    <div>
      <Button
        className='justify-content-center text-sm p-0 mt-2'
        text
        icon='pi pi-arrow-circle-left'
        onClick={() => {
          return navigate(`/products/${productId}/pcf`);
        }}
        label={t('productDetailsPage.tabs.pcfTab.buttons.goBackButton')}
      />
      <h4>{t('productDetailsPage.tabs.pcfTab.footprintDetailsText')}</h4>
    </div>
  );
};

export default FootprintDetailsPage;
