import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import AmplifyApiService from '../../services/AmplifyApiService';
import IAmplifyApiConfig from '../../shared/interfaces/IAmplifyApiConfig';
import { IFootPrint, IFootPrintDetails } from '../../shared/interfaces/IFootprint';

const amplifyApiService = new AmplifyApiService();

/**
 * This function retrieves footprints by product ID using a specified API configuration and query
 * options.
 * @param {string} productId - The `productId` parameter is a string that represents the unique
 * identifier of a product for which you want to retrieve footprints.
 * @param options - The `options` parameter in the `useGetFootprintsByProductId` function is used to
 * provide additional configuration options for the query. These options can include settings such as
 * caching, polling intervals, error handling, and more. By default, an empty object is provided as the
 * default value for the `
 * @returns The `useGetFootprintsByProductId` custom hook is being returned. This hook is used to fetch
 * footprints data by product ID using a specified API configuration and query function.
 */
export const useGetFootprintsByProductId = (
  productId: string,
  options: UseQueryOptions<IFootPrint[], Error> = {},
): UseQueryResult<IFootPrint[], Error> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: 'footprints',
    init: {
      queryStringParameters: {
        product_id: productId, // eslint-disable-line camelcase
      },
    },
  };

  const query = useQuery<IFootPrint[], Error>({
    queryKey: ['list-footprint', productId],
    queryFn: async (): Promise<IFootPrint[]> => {
      const response = await amplifyApiService.get<IFootPrint[]>({
        config: amplifyApiConfig,
      });

      return response || [];
    },
    ...options,
  });

  return query;
};

/**
 * The function `useGetFootprintById` retrieves a product footprint by its ID using an API call and
 * returns the query result.
 * @param {string} footprintId - The `footprintId` parameter is a string that represents the unique
 * identifier of a footprint.
 * @param options - The `options` parameter in the `useGetFootprintById` function is used to provide
 * additional configuration options for the query. These options can include settings such as caching
 * behavior, polling intervals, error handling, and more. By default, the `options` parameter is an
 * empty object `{}` but
 * @returns The `useGetFootprintById` custom hook is being returned, which takes a `footprintId` string
 * and optional `options` object as parameters and returns a `UseQueryResult<IFootPrintDetails[]>` object. This
 * hook fetches data for a specific footprint by its ID using the `amplifyApiService` and returns the
 * response as an array of `IFootPrintDetails` objects.
 */
export const useGetFootprintById = (
  footprintId: string,
  options: UseQueryOptions<IFootPrintDetails[], Error> = {},
): UseQueryResult<IFootPrintDetails[]> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: `footprints/${footprintId}`,
    init: {},
  };

  const query = useQuery<IFootPrintDetails[], Error>({
    queryKey: ['get-footprint', footprintId],
    queryFn: async (): Promise<IFootPrintDetails[]> => {
      const response = await amplifyApiService.get<IFootPrintDetails[]>({
        config: amplifyApiConfig,
      });

      return response;
    },
    ...options,
  });

  return query;
};
